<template>
    <div class="safeSetting">
       <h3>安全设置</h3>
        
    <a-form :model="formState" layout="vertical" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-item label="ID号" class="item">
        <!-- <a-input v-model:value="formState.name" /> -->
            
                <p>
                    <span>{{ formState.nickname }}</span>
                    <!-- <span @click="emdit">修改</span> -->
                </p>
        </a-form-item>
        <a-form-item label="手机号" class="item">
        <!-- <a-input v-model:value="formState.name" /> -->      
            <p>
                    <span>{{ formState.phone }}</span>
                    <span @click="showModal()">修改</span>
            </p>
                <a-modal 
                v-model:visible="visible" 
                title="修改密码"  
                centered 
                ok-text="确认"
                cancel-text="取消"
                @ok="handleOk">
                    <a-form :model="form">
                        <a-form-item >
                            <a-input  placeholder="6-8位字母加数字"/>
                        </a-form-item>
                        <a-form-item  name="password">
                            <a-input v-model:value="form.password" placeholder="确认新密码"/>
                        </a-form-item>
                        <a-form-item  name="captcha" style="display: flex;justify-content: space-between;">
                            <a-input v-model:value="form.captcha" placeholder="验证码" style="width: 350px;"/>
                            <a-button v-if="isShow" type="link" @click="getCaptcha">
                                获取验证码
                            </a-button>
                            <span v-else style="color: skyblue;">
                                {{countdownSeconds}}秒
                            </span>
                            <!-- <a-button v-else >11</a-button> -->
                            <!-- <a-statistic-countdown
                                :value="countdownSeconds"
                                style="margin-right: 50px"
                                @finish="onFinish"
                                :format="'s 秒'"
                            >
                            </a-statistic-countdown> -->

                        </a-form-item>
                    </a-form>
                </a-modal>
        </a-form-item>
    
    </a-form>

    </div>
</template>

<script setup>
import { onMounted, reactive, ref } from "vue";
import {emditPassword, getPhoneCode, getUserInfo} from '../../api/safeSetting'

const formState = reactive({
    nickname:'',
    phone:'',

})

const form = reactive({
    captcha:'',
    password:''
})

const visible = ref(false)
const showModal = () => {
      visible.value = true;
};


    const countdownSeconds = ref(5);
    // let secondsLeft = countdownSeconds;



const isShow = ref(true)
const getCaptcha = async()=>{
    isShow.value = false
    let res = await getPhoneCode()
    if(res.code==200){
        console.log(res);
    }
    const countdown = setInterval(() => {
    if (countdownSeconds.value > 0) {
        console.log(`倒计时剩余时间：${countdownSeconds.value}秒`);
        countdownSeconds.value--;
    } else {
        // console.log("倒计时结束");
        clearInterval(countdown);
        isShow.value = true
        return countdownSeconds.value = 5
    }
    }, 1000);
    
}

const handleOk = async()=>{
    let res = await emditPassword({
        password:form.password,
        captcha:form.captcha
    })
    if(res.code == 200){
        console.log(res);
    }
}


const getUser = async()=>{
    let res = await getUserInfo()
    if(res.code==200){
        console.log(res);
        formState.nickname = res.data.nickname
        formState.phone = res.data.phone
    }
}

onMounted(()=>{
    getUser()
})
</script>

<style lang="less" scoped>
    .safeSetting{
        width: 100%;
        background-color: #fff;
        height: 500px;
        padding: 10px;
        h3{
            color: #d90d0d;
            
        }
        
        :deep(.item){
            height: 100px;
            background-color: #e9e7e7;
            // font-size: 30px;
            padding: 5px;
            margin-top: 20px;
            p{
                background-color: #fff;
                height: 60px;
                line-height: 60px;
                display: flex;
                padding-left: 5px;
                padding-right: 10px;
                justify-content: space-between;
                span{
                    display: inline-block;
                    &:nth-last-child(1){
                        &:hover{
                            color: #d90d0d;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
</style>