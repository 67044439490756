import request from "@/utils/request"


export const getUserInfo = ()=>{
    return request('/api/front/user/center/info','get')
}

export const emditPassword = (data)=>{
    return request('api/front/user/register/reset','post',data)
}

export const getPhoneCode =  ()=>{
    return request('/api/front/user/phone/code','post',{noAuth:true})
}